import { EmailIcon } from "../../icons";
import { StorSelfIconButton } from "../StorSelfIconButton";
import { mainRoutes } from "../../../router/routes";

/**
 * ContactUsButton component.
 * @param props - Component props.
 * @param props.existingCustomer - Determines which contact us page it is routed to.
 *                                  If true, routes to the existing customer page.
 *                                  If false or undefined, routes to the default contact us page.
 */
export const ContactUsButton = ({
    existingCustomer
}: {
    existingCustomer?: boolean;
}) => {
    const contactUsRoute = existingCustomer ? (mainRoutes.ExistingCustomer + mainRoutes.ContactUs) : mainRoutes.ContactUs;

    return (
        <StorSelfIconButton
            Icon={EmailIcon}
            text="Contact Us"
            analyticsTag="To Contact Us"
            to={contactUsRoute}
        />
    );
};
