import { PageTitle, Subtitle1, Subtitle2 } from "../brand/typographies";
import { StorSelfButton } from "../components/buttons/StorSelfButton";
import { Section } from "../components/layout/Section";
import { FacilityAddress } from "../components/FacilityAddress";
import { companyConfig } from "../globalContext";
import { useSelectFacility } from "../hooks/useSelectFacility";
import { Box } from "@mui/material";
import useAnalytics from "../hooks/useAnalytics";
import { useStoreSelfNavigation } from "../hooks/useStorSelfNavigation";

export const LandingPage = () => {
    return (
        <>
            <PageTitle>{companyConfig.welcomeStatement}</PageTitle>

            <FacilityAddress />

            <Section sx={{ mt: 4 }}>
                <StorSelfButton
                    to="/new-customer"
                    variant="outlined"
                >
                    New Customers
                </StorSelfButton>

                <StorSelfButton
                    to="/existing-customer"
                >
                    Existing Customers
                </StorSelfButton>
            </Section>

            <AuctionBuyerLink />
        </>
    );
};

const AuctionBuyerLink = () => {
    const navigate = useStoreSelfNavigation();
    const { trackClickEvent } = useAnalytics("AuctionBuyerLink")

    return (
        <Box
            mt={2}
            display={'flex'}
            width={'100%'}
            flexDirection={"column"}
            justifyContent={'center'}
            alignItems={'center'}>

            <Subtitle1>Auction Buyer?</Subtitle1>
            <Subtitle2 sx={{ cursor: "pointer" }} onClick={() => {
                trackClickEvent("To Auction Buyer")
                navigate("/auction-buyer")
            }} bold brandColor>Click Here</Subtitle2>
        </Box>
    );
};
