import React, { useContext, useMemo } from 'react';
import {
    Box,
    Button,
    Grid,
    Divider,
    getContrastRatio,
    Skeleton,
} from '@mui/material';
import { isMobile } from 'react-device-detect';

import { CardFacilityAddress } from './[components]/CardFacilityAddress';
import { Colors } from '../../brand/colors';
import { FacilitiesContext } from "../../providers/facility-provider/FacilitiesContext";
import { LedgerRow } from '../../components/LedgerRow';
import { PageTitle, Subtitle1, Subtitle2 } from '../../brand/typographies';
import { useAppContext } from '../../hooks/useAppContext';
import { useMoveoutEntryNavigation } from '../moveout/[hooks]/useMoveoutEntryNavigation';
import { usePaymentNavigation } from '../payments/[hooks]/usePaymentNavigation';
import { useStoreSelfNavigation } from '../../hooks/useStorSelfNavigation';
import { WifiIcon } from '../../components/icons';
import useAnalytics from '../../hooks/useAnalytics';
import { RentSpaceButton } from '../../components/buttons/NavigationButtons/RentSpaceButton';
import { FacilityAccountCard } from './[components]/FacilityAccountCard';
import { usePrePayMonthsAllowed } from '../../hooks/usePrePayMonthsAllowed';
import LoadingButton from '@mui/lab/LoadingButton';
import { ILedger } from '../../types/ILedger';
import { IFacility } from '../../types/IFacility';

export const AccountPage = () => {
    const navigate = useStoreSelfNavigation();
    const { overview } = useMoveoutEntryNavigation()
    const { paymentAmount } = usePaymentNavigation()
    const { facilities } = useContext(FacilitiesContext)
    const { phoneNumber, ledgers, loadingAppContext } = useAppContext()
    const { allowedPrePayMonths, pendingPrePayMonths } = usePrePayMonthsAllowed();


    // Function to find ledgers for a given facility
    const getLedgersForFacility = (facilityId: string) => {
        return ledgers.filter((ledger) => ledger.facilityId === facilityId);
    };

    // Filter facilities to only those with a matching ledger
    const facilitiesWithLedgers = facilities.filter((facility) =>
        getLedgersForFacility(facility.id).length > 0
    );

    const { trackClickEvent } = useAnalytics("Account")

    return (
        <Box sx={{ flexGrow: 1 }}>
            <PageTitle>Account</PageTitle>

            <Grid container spacing={2}>

                {/** Render a skeleton loader */}
                {!facilitiesWithLedgers?.length && loadingAppContext &&
                    <Skeleton variant="rectangular" width="100%" height={200} sx={{ mt: 2 }} />
                }

                {
                    !loadingAppContext && !facilitiesWithLedgers?.length &&
                    <>
                        <Subtitle1 sx={{ mt: 2 }} bold center>Could not find any units.</Subtitle1>
                        <RentSpaceButton />
                    </>
                }

                {facilitiesWithLedgers.map((facility) => (
                    <FacilityAccountCard key={facility.id}>
                        <CardFacilityAddress facility={facility} />

                        {getLedgersForFacility(facility.id).map((ledger, index, array) => {
                            console.log({ ledger })
                            return (

                                <>
                                    <LedgerRow
                                        loading={false}
                                        ledger={ledger} />

                                    <Box sx={{ display: 'flex', mt: 1, gap: "8px" }}>
                                        <PayButton
                                            pendingPrePayMonths={pendingPrePayMonths}
                                            allowedPrePayMonths={allowedPrePayMonths}
                                            loadingAppContext={loadingAppContext}
                                            ledger={ledger}
                                            trackClickEvent={trackClickEvent}
                                            paymentAmount={paymentAmount}
                                            phoneNumber={phoneNumber}
                                            facility={facility}
                                        />
                                        <SmallButton
                                            outlined
                                            onClick={() => {
                                                trackClickEvent("Navigate - Moveout Overview")
                                                overview(phoneNumber, ledger.id, facility)
                                            }}>
                                            Move Out
                                        </SmallButton>
                                    </Box>

                                    <Box sx={{ width: "100%", boxSizing: "border-box", px: 1 }}>
                                        <Divider sx={{ my: 1, borderColor: Colors.primary() }} />
                                    </Box>
                                    <AccessUnitLink
                                        onClick={() => {
                                            trackClickEvent("Navigate - Access Facility")
                                            navigate(`/access?facilityId=${facility.id}`)
                                        }}
                                    />

                                    {/** If there is another unit at the facility, render a divider */}
                                    {index < array.length - 1 && <Divider sx={{ my: 2, borderColor: Colors.primary() }} />}
                                </>
                            )
                        })}
                    </FacilityAccountCard>
                ))}
            </Grid>
        </Box>
    );
};

const PayButton = ({
    pendingPrePayMonths,
    allowedPrePayMonths,
    loadingAppContext,
    ledger,
    facility,
    trackClickEvent,
    paymentAmount,
    phoneNumber
}: {
    pendingPrePayMonths?: boolean,
    allowedPrePayMonths: number,
    loadingAppContext?: boolean,
    ledger: ILedger,
    facility: IFacility,
    trackClickEvent: (arg0: string) => void,
    paymentAmount: any,
    phoneNumber: string

}) => {
    const isLoading = useMemo(() => {
        if (!loadingAppContext && ledger.balance > 0) {
            return false;
        }
        if (loadingAppContext || pendingPrePayMonths) {
            return true;
        }

        return false;
    }, [loadingAppContext, pendingPrePayMonths, ledger])

    const text = useMemo(() => {
        if (ledger.balance) {
            return "Pay"
        }
        if (ledger.balance <= 0) {
            if (allowedPrePayMonths) {
                return "Prepay"
            }
            return "Pay"
        }
    }, [ledger.balance, allowedPrePayMonths])

    return (
        <SmallButton
            pending={isLoading}
            disabled={!allowedPrePayMonths && ledger.balance <= 0}
            onClick={() => {
                trackClickEvent("Navigate - Payment Amount")
                paymentAmount({ phoneNumber, ledgerId: ledger.id, facilityId: facility.id })
            }}
        >
            {text}
        </SmallButton>
    )
}

const SmallButton = ({ onClick, outlined, children, disabled, pending, href, target }: {
    disabled?: boolean,
    pending?: boolean,
    onClick?: () => void,
    outlined?: boolean,
    children: React.ReactNode,
    href?: string;
    target?: string;
}) => (
    <LoadingButton
        {...(href && {
            href,
            // target
        })}
        onClick={onClick}
        sx={{
            height: "40px",
            fontSize: "17px",
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: "22px", /* 129.412% */
            letterSpacing: "-0.07px",
            ...(outlined ? {} : {
                color: getContrastRatio(Colors.primary(), "#FFFFFF") > 2.7 ? "#FFFFFF" : "#000000",
                borderColor: Colors.primary(),
            }),
        }}
        disabled={disabled}
        loading={pending}
        variant={outlined ? "outlined" : "contained"}>
        {children}
    </LoadingButton>
)

const AccessUnitLink = ({ onClick }: { onClick: () => void }) => (
    <Box sx={{ justifyContent: "center", alignItems: "center", display: "flex", svg: { height: "16px", width: "16px" }, gap: "8px", path: { fill: Colors.primary() }, height: "20px" }}>
        <WifiIcon />
        <Subtitle2 sx={{ m: 0, mb: "4px", fontWeight: 700, cursor: "pointer" }} onClick={onClick} bold center brandColor underlined>
            Access Unit</Subtitle2>
    </Box>
)
